var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c("header", [_c("header-nav")], 1),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("footer-bar"),
      _vm._v(" "),
      _c("easemob", { attrs: { easemob: _vm.easemob } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "info-part" }, [
      _c("img", {
        staticClass: "app-icon",
        attrs: { src: require("./img/icon.png") },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "information" }, [
        _c("strong", [_vm._v("Fintopia. Inc")]),
        _vm._v(
          " is a Beijing-based leading fintech conglomerate in China, specializing in inclusive finance and wealth management, in addition to payment technology, marketplace lending, crowdfunding, robo-advisory, insurance technology, and blockchain products and services. Fintopia now has more than 5 locations operating worldwide, including U.S., Japan, Thailand, Indonesia.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "product-part", attrs: { id: "products" } },
      [
        _c("h3", [_vm._v("Our Products")]),
        _vm._v(" "),
        _c("div", { staticClass: "card card-first" }, [
          _c("img", { attrs: { src: require("./img/card-first.png") } }),
          _vm._v(" "),
          _c("div", { staticClass: "rates" }, [
            _c("img", { attrs: { src: require("./img/badge.png") } }),
            _vm._v(" "),
            _c("span", { staticClass: "rate" }, [_vm._v("12.00%")]),
            _vm._v(" "),
            _c("span", { staticClass: "note" }, [
              _vm._v("Annual interest rates"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "duration" }, [
            _c("span", { staticClass: "day" }, [_vm._v("5 Days")]),
            _vm._v(" "),
            _c("span", { staticClass: "note" }, [_vm._v("Duration")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card card-fixed" }, [
          _c("img", { attrs: { src: require("./img/card-fixed.png") } }),
          _vm._v(" "),
          _c("ul", { staticClass: "product-list" }, [
            _c("li", { staticClass: "product-item" }, [
              _c("p", { staticClass: "product-name" }, [_vm._v("360 Days")]),
              _vm._v(" "),
              _c("p", { staticClass: "rate" }, [_vm._v("8.80%")]),
              _vm._v(" "),
              _c("p", { staticClass: "note" }, [_vm._v("Annual earnings")]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "product-item" }, [
              _c("p", { staticClass: "product-name" }, [_vm._v("180 Days")]),
              _vm._v(" "),
              _c("p", { staticClass: "rate" }, [_vm._v("7.50%")]),
              _vm._v(" "),
              _c("p", { staticClass: "note" }, [_vm._v("Annual earnings")]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "product-item" }, [
              _c("p", { staticClass: "product-name" }, [_vm._v("90 Days")]),
              _vm._v(" "),
              _c("p", { staticClass: "rate" }, [_vm._v("7.00%")]),
              _vm._v(" "),
              _c("p", { staticClass: "note" }, [_vm._v("Annual earnings")]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "product-item" }, [
              _c("p", { staticClass: "product-name" }, [_vm._v("30 Days")]),
              _vm._v(" "),
              _c("p", { staticClass: "rate" }, [_vm._v("6.00%")]),
              _vm._v(" "),
              _c("p", { staticClass: "note" }, [_vm._v("Annual earnings")]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }