/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import FooterBar from 'moneycan/app/moneycan-loan/component/footer-bar';
import HeaderNav from 'moneycan/app/moneycan-loan/component/header-nav';
import {EASEMOB} from 'moneycan/common/constant/config';

export default {
    name: 'Index',

    title: 'Home - Moneycan',

    head() {
        return {};
    },

    data() {
        return {easemob: EASEMOB};
    },

    components: {
        HeaderNav,
        FooterBar
    }
};
